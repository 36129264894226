import { FC, ReactNode, createContext, useState } from "react";
import Cookies from "js-cookie";

import { Order } from "../../entities/Order";
import { InvoiceData, Item } from "../../entities/InvoiceInfo";
import { addDays } from "date-fns";
import { RMAData, RMADataResults } from "../../entities/RMAData";
import { isEmpty } from "lodash";
import { isEnableItemsPerLine } from "../../utils/item-utils";

type OrderProviderType = {
  children: ReactNode;
};

export const generateBaseImageUrl = (
  netSuiteAccountId: string,
  imagePath: string,
  isCustomImage: boolean
) => {
  if (imagePath && isCustomImage) {
    return imagePath;
  } else if (imagePath) {
    return `https://${netSuiteAccountId}.app.netsuite.com${imagePath}`;
  } else {
    return "/site/images/no-image-available.jpeg";
  }
};

const OrderContext = createContext({
  currentOrders: [] as Array<Order>,
  rmaItems: [] as Array<Item>,
  nonAssociatedDiscountItems: [] as Array<Item>,
  rmaList: [] as Array<RMAData>,
  handleSetCurrentInvoiceData: (invoiceData: InvoiceData) => {},
  handleSetCurrentRma: (rmaData: RMADataResults["results"]) => {},
  selectedProductsToReturn: [] as Array<Order>,
  currentRma: {} as RMAData,
  handleSetSelectedProductsToReturn: (orders: Array<Order>) => {},
  handleClearSessionStorage: () => {},
});

const OrderProvider: FC<OrderProviderType> = ({ children }) => {
  const [currentOrders, setCurrentOrders] = useState<Array<Order>>(
    sessionStorage.getItem("orders")
      ? JSON.parse(sessionStorage.getItem("orders") as string)
      : ([] as Array<Order>)
  );
  const [selectedProductsToReturn, setSelectedProductsToReturn] = useState<
    Array<Order>
  >(
    sessionStorage.getItem("selectedProductsToReturn")
      ? JSON.parse(sessionStorage.getItem("selectedProductsToReturn") as string)
      : ([] as Array<Order>)
  );

  const [currentRma, setCurrentRma] = useState<RMAData>(
    sessionStorage.getItem("currentRma")
      ? JSON.parse(sessionStorage.getItem("currentRma") as string)
      : ({} as RMAData)
  );

  const [rmaItems, setRmaItems] = useState<Array<Item>>(
    sessionStorage.getItem("rmaItems")
      ? JSON.parse(sessionStorage.getItem("rmaItems") as string)
      : ([] as Array<Item>)
  );

  const [nonAssociatedDiscountItems, setNonAssociatedDiscountItems] = useState(
    sessionStorage.getItem("nonAssociatedDiscountItems")
      ? JSON.parse(
          sessionStorage.getItem("nonAssociatedDiscountItems") as string
        )
      : ([] as Array<Item>)
  );

  const [rmaList, setRmaList] = useState<Array<RMAData>>(
    sessionStorage.getItem("rmaList")
      ? JSON.parse(sessionStorage.getItem("rmaList") as string)
      : ([] as Array<RMAData>)
  );

  const netSuiteAccountId = Cookies.get("application")
    ? JSON.parse(String(Cookies.get("application"))).netSuiteAccountId
    : "";

  const handleSetCurrentInvoiceData = (invoiceData: InvoiceData) => {
    const orders: Item[] = (
      invoiceData?.invoice.map((invoice) => invoice.items).flat() || []
    ).reduce((acc: Item[], item: Item) => {
      // Search if the item exist in the accumulator
      const existingItem = acc.find(
        (i) => i.item === item.item && item.itemtype !== "Discount"
      );

      if (existingItem && !isEnableItemsPerLine) {
        // if the item exist, we should sum 'amount' and 'quantity'
        existingItem.amount = (
          Number(existingItem.amount) + Number(item.amount)
        ).toFixed(2);
        existingItem.quantity = (
          Number(existingItem.quantity) + Number(item.quantity)
        ).toString();
      } else {
        // if the item exist, we added to the accumulator
        acc.push({ ...item });
      }

      return acc;
    }, []);

    const summaryInfo = invoiceData?.invoice.reduce(
      (acc, invoice) => {
        return {
          netamountnotax: (
            Number(acc.netamountnotax) + Number(invoice.summary.netamountnotax)
          ).toFixed(2),
          total: (Number(acc.total) + Number(invoice.summary.total)).toFixed(2),
          taxtotal: (
            Number(acc.taxtotal) + Number(invoice.summary.taxtotal)
          ).toFixed(2),
          shippingcost: (
            Number(acc.shippingcost) + Number(invoice.summary.shippingcost)
          ).toFixed(2),
        };
      },
      { netamountnotax: "0", total: "0", taxtotal: "0", shippingcost: "0" }
    );
    const customerInfo = invoiceData?.invoice[0].customer;

    console.log("orders :>> ", orders);

    // remove first item order, is a netsuite stuff
    const slicedOrders = orders.slice(1);
    const discountOnTheLineAssociatedWithAnItem: number[] = [];
    const mappedOrders = slicedOrders
      ?.map((order, index) => {
        let discount = 0;
        let discountName = "";
        // check if the follow element is a discount and has a negative amount
        if (
          slicedOrders[index + 1]?.itemtype === "Discount" &&
          Number(slicedOrders[index + 1]?.amount) < 0 &&
          slicedOrders[index]?.itemtype === "InvtPart"
        ) {
          if (
            slicedOrders.length === index + 2 &&
            Math.abs(Number(slicedOrders[index + 1].amount)) >
              Math.abs(
                Number(slicedOrders[index].rate) -
                  Number(slicedOrders[index].amount)
              )
          ) {
          } else {
            discount =
              Number(slicedOrders[index + 1]?.amount) / Number(order?.quantity);
            discountName = slicedOrders[index + 1]?.itemName;
            discountOnTheLineAssociatedWithAnItem.push(index + 1);
          }
        }

        // Add logic to check if the item was alredy returned
        // TODO: move to func
        let quantityReturned = 0;
        // @ts-ignore
        invoiceData.rmaInfo.results.forEach((rmaInfo) => {
          let returnedItem = rmaInfo.items.find(
            // @ts-ignore
            (rItem) => rItem.item === order.item
          );
          if (returnedItem) {
            quantityReturned += Math.abs(parseInt(returnedItem.quantity));
          }
        });

        // TODO: consider the case that has discount in line and in header

        return {
          id: String(order?.item),
          name: order?.itemName,
          itemtype: order?.itemtype,
          img: generateBaseImageUrl(
            String(netSuiteAccountId),
            order?.itemThumbnail,
            order.isCustomImage
          ),
          price: Number(order?.amount),
          rate: Number(order?.rate),
          discount,
          discountName,
          description: "",
          eligible: order?.eligible,
          quantity: Number(order?.quantity),
          etail_tax_item: Number(order?.etail_tax_item),
          return_date: order?.custcol_ucp_return_date
            ? addDays(
                new Date(order?.custcol_ucp_return_date ?? ""),
                Number(order?.custcol_ucp_return_item_eligibility ?? 0)
              )
            : null,
          quantityReturned,
          line: order?.line,
        } as Order;
      })
      ?.filter(
        (order) =>
          order?.itemtype === "InvtPart" || order?.itemtype === "NonInvtPart"
      );
      
    const discountInfo = orders
      ?.filter(
        (order, index) =>
          order?.itemtype === "Discount" &&
          Number(order.amount) < 0 &&
          !discountOnTheLineAssociatedWithAnItem.includes(index)
      )
      ?.reduce((acc, order) => acc + Number(order.amount), 0);

    const nonAssociatedDiscountItems = slicedOrders.filter(
      (rmaItem, index) =>
        rmaItem.itemtype === "Discount" &&
        !discountOnTheLineAssociatedWithAnItem.includes(index)
    );

    sessionStorage.setItem(
      "nonAssociatedDiscountItems",
      JSON.stringify(nonAssociatedDiscountItems)
    );
    setNonAssociatedDiscountItems(nonAssociatedDiscountItems);
    sessionStorage.setItem("orders", JSON.stringify(mappedOrders));
    sessionStorage.setItem("settings", JSON.stringify(invoiceData?.settings));
    sessionStorage.setItem("invoice", JSON.stringify(invoiceData?.invoice[0]));
    sessionStorage.setItem("customerInfo", JSON.stringify(customerInfo));
    sessionStorage.setItem("summaryInfo", JSON.stringify(summaryInfo));
    sessionStorage.setItem("discountInfo", JSON.stringify(discountInfo));
    setCurrentOrders(mappedOrders);
    if (sessionStorage.getItem("step") === "1") {
      setSelectedProductsToReturn([]);
    }
  };

  const handleSetCurrentRma = (rmaData: RMADataResults["results"]) => {
    const lastRMA = rmaData.results.length - 1;
    const rmaCurrentData = rmaData.results[lastRMA];
    setRmaList(rmaData.results);
    setCurrentRma(rmaData.results[lastRMA]);
    if (!isEmpty(rmaCurrentData)) {
      const rmaItems = rmaData.results[lastRMA].items;
      const discountOnTheLineAssociatedWithAnItem: number[] = [];

      const mappedRmaItems = rmaItems
        .map((rmaItem, index) => {
          let discount = 0;
          let discountName = "";
          // check if the follow element is a discount and has a negative amount
          if (
            rmaItems[index + 1]?.itemtype === "Discount" &&
            Number(rmaItems[index + 1]?.amount) !== 0 &&
            rmaItems[index]?.itemtype === "InvtPart"
          ) {
            discount =
              Number(rmaItems[index + 1]?.amount) / Number(rmaItem?.quantity);
            discountName = rmaItems[index + 1]?.itemName;
            discountOnTheLineAssociatedWithAnItem.push(index + 1);
          }

          return {
            id: rmaItem.item,
            rate: rmaItem.rate,
            name: rmaItem.itemName,
            discount,
            discountName,
            comments: rmaItem.custcol_ucp_return_comments,
            returnReason: rmaItem.custcol_ucp_return_reason,
            quantityReturned: rmaItem.custcol_ucp_qty_returned,
            ...rmaItem,
          } as any as Item;
        })
        .filter(
          (item) =>
            item.itemtype === "InvtPart" || item.itemtype === "NonInvtPart"
        );

      const nonAssociatedDiscountItems = rmaData.results[lastRMA].items.filter(
        (rmaItem, index) => {
          return (
            rmaItem.itemtype === "Discount" &&
            !discountOnTheLineAssociatedWithAnItem.includes(index)
          );
        }
      );

      setRmaItems(mappedRmaItems);
      setNonAssociatedDiscountItems(nonAssociatedDiscountItems);
      sessionStorage.setItem(
        "nonAssociatedDiscountItems",
        JSON.stringify(nonAssociatedDiscountItems)
      );
      sessionStorage.setItem("rmaItems", JSON.stringify(mappedRmaItems));
      sessionStorage.setItem("rmaList", JSON.stringify(rmaList));
      sessionStorage.setItem("currentRma", JSON.stringify(rmaCurrentData));
    }
  };

  const handleSetSelectedProductsToReturn = (orders: Array<Order>) => {
    setSelectedProductsToReturn(orders);
    sessionStorage.setItem("selectedProductsToReturn", JSON.stringify(orders));
  };

  const handleClearSessionStorage = () => {
    sessionStorage.clear();
    setCurrentOrders([]);
    setSelectedProductsToReturn([]);
  };

  return (
    <OrderContext.Provider
      value={{
        currentOrders,
        handleSetCurrentInvoiceData,
        selectedProductsToReturn,
        handleSetSelectedProductsToReturn,
        handleClearSessionStorage,
        handleSetCurrentRma,
        currentRma,
        rmaItems,
        nonAssociatedDiscountItems,
        rmaList,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider };
