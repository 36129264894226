import { FC, useMemo, useState, useEffect } from "react";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";

import classes from "./OrderSummary.module.scss";

import Title from "../../../components/Common/Title";
import Paragraph from "../../../components/Common/Paragraph";
import { Order } from "../../../entities/Order";
import { formatAmount } from "../../../utils/formatter-utils";
import useInvoiceData from "../../../hooks/useInvoiceData";
import CustomerInfo from "../CustomerInfo";

type OrderSummaryProps = {
  currentOrders: Order[];
};

type Item = {
  value: number | undefined;
  label: string;
  type: "amount" | "string";
};

type OrderSummaryPriceInfo = {
  subtotal: Item;
  discount: Item;
  taxes: Item;
  shippingCost: Item;
  totalItems: Item;
  total: Item;
};

const OrderSummary: FC<OrderSummaryProps> = ({ currentOrders }) => {
  const [summaryPriceInfo, setSummaryPriceInfo] =
    useState<OrderSummaryPriceInfo | null>(null);
  const [searchParams] = useSearchParams();
  const { summaryInfo, discountInfo, nonAssociatedDiscountItems } =
    useInvoiceData();

  const orderNumber = useMemo(
    () => searchParams.get("order-number") ?? undefined,
    [searchParams]
  );

  useEffect(() => {
    const calculateDiscountAndSubtotal = () => {
      const sumOfPriceItems = currentOrders?.reduce(
        (acc, order) => acc + order.price,
        0
      );

      const sumOfPriceDiscounts = currentOrders?.reduce(
        (acc, order) => acc + Math.abs(order.discount),
        0
      );

      let discount = Math.abs(discountInfo) - sumOfPriceDiscounts;
      discount = discount === 0 ? 0 : discount > 0 ? -discount : discount;

      const subtotal =
        sumOfPriceItems + Math.abs(discountInfo) - sumOfPriceDiscounts;
      return { subtotal, discount };
    };

    const discountType = nonAssociatedDiscountItems?.[0]?.itemName
      ? `(${nonAssociatedDiscountItems?.[0]?.itemName})`
      : "";

    const taxes =
      Number(summaryInfo?.taxtotal) +
      currentOrders.reduce((sum, item) => {
        if (Number(summaryInfo?.taxtotal) > 0) {
          return sum;
        }
        return sum + Number(item?.etail_tax_item ?? 0) * item.quantity;
      }, 0);

    if (!isEmpty(summaryInfo) && !isEmpty(currentOrders)) {
      const { discount, subtotal } = calculateDiscountAndSubtotal();
      setSummaryPriceInfo({
        subtotal: { value: subtotal, label: "Subtotal", type: "amount" },
        discount: {
          value: discount,
          label: `Discount ${discountType}`,
          type: "amount",
        },
        taxes: {
          value: taxes,
          label: "Taxes",
          type: "amount",
        },
        shippingCost: {
          value: Number(summaryInfo?.shippingcost),
          label: "Shipping Cost",
          type: "amount",
        },
        totalItems: {
          value: currentOrders?.reduce((acc, order) => acc + order.quantity, 0),
          label: "Total Items",
          type: "string",
        },
        total: {
          value: Number(summaryInfo?.total),
          label: "Total:",
          type: "amount",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <aside className={classNames(classes.orderSummary)}>
      <header>
        <Title kind="h3" size="xs">
          Order Summary
        </Title>
        <Paragraph size="xxxs">
          Details of order {orderNumber ? `#${orderNumber}` : "-"}
        </Paragraph>
      </header>
      <div className={classes.summaryContent}>
        <CustomerInfo />
        <div className={classes.totalPrice}>
          {summaryPriceInfo &&
            Object.values(summaryPriceInfo).map((item, index) => (
              <div key={index} className={classes.totalPriceRow}>
                <span>{item.label}</span>
                <span>
                  {item.type === "amount"
                    ? formatAmount(Number(item.value))
                    : item.value}
                </span>
              </div>
            ))}
        </div>
      </div>
    </aside>
  );
};

export default OrderSummary;
